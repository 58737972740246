<template>
  <div class="zem-collapse-table rows">
    <div
      v-for="(item, index) in elements"
      :key="'a' + index"
      class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]"
    >
      <div :class="{'required-field': isEditing || isCreating}" class="zem-collapse-table__column title">
        {{ item.title }}
      </div>
      <div
        v-if="!isEditing && !isCreating"
        :class="{'overflow-unset': item.title === 'Расположение'}"
        class="zem-collapse-table__column text"
      >
        {{ values(item) }}
      </div>
      <zem-input
        v-else
        v-model="item.data"
        :type="item.type === 'numeric' ? 'number' : 'text'"
        class="mt-0"
      ></zem-input>
    </div>
  </div>
</template>

<script>
import ZemInput from '@/components/ui/ZemInput.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import ZemRadioButton from '@/components/ui/ZemRadioButton.vue'
import {logger} from '@/assets/scripts/scripts'

export default {
  components: {
    ZemRadioButton,
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
  },
  props: {
    elements: {
      type: Array,
      default: () => [],
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isCreating: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    idx: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {}
  },
  methods: {
    logger,
    values(item) {
      if (item.type === 'list') {
        return item.data.title
      } else if (item.type === 'groups') {
        let valuesToString = ''
        item.data.forEach((el, index, arr) => {
          valuesToString += el.title
          if (arr.length !== index + 1) {
            valuesToString += ', '
          }
        })
        return valuesToString
      } else if (item['input_name'] === 'bundle') {
        return item.data.title
      } else {
        return item.data
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-dropdown {
  margin-top: 0;
}
</style>
