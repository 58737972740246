var render = function render(){var _vm=this,_c=_vm._self._c;return _c('zem-card',{staticClass:"tw-flex-col"},[_c('section',{staticClass:"tw-w-full tw-flex tw-justify-between tw-items-start"},[_c('div',[(_vm.editing || _vm.creating)?[_c('zem-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"tw-flex tw-items-center tw-text-xs tw-text-gray-700"},[_c('span',{staticClass:"tw-w-1.5 tw-h-1.5 tw-bg-[#0B9999] tw-rounded-full tw-mr-1"}),_vm._v(" ID "+_vm._s(_vm.id)+" ")])]:[_c('zem-card-title',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"tw-flex tw-items-center tw-text-xs tw-text-gray-700"},[_c('span',{staticClass:"tw-w-1.5 tw-h-1.5 tw-bg-[#0B9999] tw-rounded-full tw-mr-1"}),_vm._v(" ID "+_vm._s(_vm.id)+" ")])],(!_vm.closeAcc([3]))?_c('div',{staticClass:"tw-flex tw-items-center tw-text-xs tw-text-gray-700"},[_c('span',{staticClass:"tw-w-1.5 tw-h-1.5 tw-bg-[#0EC6C6] tw-rounded-full tw-mr-1"}),_vm._v(" ID "+_vm._s(_vm.hashId)+" ")]):_vm._e()],2),_c('div',{staticClass:"tw-flex tw-items-center tw-gap-2"},[((!_vm.elementData || _vm.creating || _vm.editing) && !_vm.loading)?[(!_vm.creating && !_vm.editing && _vm.closeAcc([5]))?[_c('ExternalLinkIcon',{directives:[{name:"tippy",rawName:"v-tippy",value:('Перейти в CRM'),expression:"'Перейти в CRM'"}],staticClass:"text-primary tw-cursor-pointer",attrs:{"size":"16"}}),_c('ListIcon',{directives:[{name:"tippy",rawName:"v-tippy",value:('История'),expression:"'История'"}],staticClass:"text-primary tw-cursor-pointer tw-outline-none",attrs:{"size":"20"},on:{"click":function($event){return _vm.$emit('on-show-events-list')}}}),(_vm.isShowEditButton)?_c('Edit3Icon',{staticClass:"text-primary tw-cursor-pointer",attrs:{"size":"16"},on:{"click":() => {
                _vm.$emit('edit-element')
              }}}):_vm._e(),_c('XIcon',{staticClass:"text-primary tw-cursor-pointer",attrs:{"size":"18"},on:{"click":_vm.closeRightSidebar}})]:(_vm.closeAcc([5, 7]))?[_c('zem-link',{staticClass:"mr-2 mobile-d-none",on:{"click":() => {
                _vm.$emit('close')
              }}},[_vm._v(" Отменить ")]),_c('zem-button',{staticClass:"mobile-d-none",on:{"click":() => {
                _vm.$emit(_vm.creating ? 'create' : 'update')
              }}},[_vm._v(" Сохранить ")])]:_vm._e()]:_vm._e(),(_vm.loading)?_c('zem-button',{attrs:{"disabled":""}},[_c('zem-preloader',{attrs:{"color":"light"}})],1):_vm._e()],2)]),_vm._t("bottom")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }