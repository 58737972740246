<template>
  <div>
    <SidebarLeft :class="{'sidebar-left-show': $store.state.sidebars.leftSidebar}">
      <FilterExchangeForm v-show="$store.state.sidebars.leftSidebar" ref="filter" @get-order="getOrder" />
    </SidebarLeft>

    <TableContainer
      ref="table-container"
      :columns="tableData || []"
      :counter-filter="$store.state.exchange.searchCount"
      :is-archive="$can('edit', 'partner-order-read-archive')"
      :is-archiving="$can('edit', 'partner-order-trash')"
      :is-loading-items="$store.state.other.isLoadingItems"
      :is-loading-new-item="$store.state.other.isLoadingNewItem"
      :is-remove-from-archive="$can('edit', 'partner-order-delete')"
      :rows="$store.state.exchange.elements"
      :show-add-button="$can('edit', 'partner-order-create')"
      :sorting-fields="sortingFields"
      :title="$t('Exchange') || ''"
      name="exchange"
      @show-right-sidebar="showRightSidebar"
      @on-selected="selectItem"
      @update-data="$refs.filter.getFilter()"
      @archive-items="archiveItems"
      @delete-item="deleteItem"
      @return-from-archive="returnFromArchive"
    />

    <SidebarRight
      :class="{
        'sidebar-right-show': $store.state.sidebars.rightSidebar,
      }"
      sidebar-name="rightSidebar"
    >
      <ExchangeDetails
        ref="exchangeDetails"
        :editOrder="editOrder"
        :newOrder="newOrder"
        @getApplication="onGetApplication"
      />

      <DetailsFilter
        v-if="selectedRows.length > 1"
        :count="selectedRows.length"
        :fields.sync="fields"
        :take-all-orders="showButtonTakeAllOrders"
      />

      <preloader-wrapper :show="$store.state.other.isLoadingItem" />
    </SidebarRight>
  </div>
</template>

<script>
import SidebarLeft from '../components/SidebarLeft'
import Table from '../components/Table'
import SidebarRight from '../components/SidebarRight'
import FilterExchangeForm from '../components/FilterExchangeForm'
import ExchangeDetails from '../components/ExchangeDetails'
import ExchangeService from '@/services/exchange.service'
import DetailsFilter from '@/components/DetailsFilter'
import {errorHandler} from '@/assets/scripts/scripts'
import messages from '@/helpers/messages'
import TableContainer from '@/components/TableContainer.vue'
import {eventBus} from '@/main'
import OrderDetails from '@/components/Orders/OrderDetails.vue'
import PreloaderWrapper from '@/components/PreloaderWrapper.vue'

export default {
  components: {
    PreloaderWrapper,
    OrderDetails,
    TableContainer,
    SidebarLeft,
    Table,
    SidebarRight,
    FilterExchangeForm,
    ExchangeDetails,
    DetailsFilter,
  },
  data() {
    return {
      isLeftSidebarVisible: true,
      isRightSidebarVisible: true,
      selectedItem: [],
      currentOrder: {},
      newOrder: true,
      editOrder: false,
      sortingFields: [
        'partner_orders.id',
        'partner_orders.created_by',
        'partner_orders.client_name',
        'partner_orders.client_phone',
        'partner_orders.client_email',
        'partner_orders.comment',
        'partner_orders.budget_start',
        'partner_orders.budget_finish',
        'partner_orders.created_at',
        'partner_orders.status_active_at',
        'partner_orders.partner_status_finish_at',
        'partner_orders.order_status_id',
        'partner_orders.winner_by',
        'partner_orders.order_partner_status_id',
        'partner_orders.address',
        'partner_orders.location_lat',
        'partner_orders.location_long',
      ],
      tableData: [
        {
          label: 'ID',
          field: 'number',
          sortField: 'partner_orders.id',
          showInTable: true,
          sort: 2,
        },
        {
          label: 'Разместил',
          field: 'createdBy.data.human.data.fio',
          sortField: 'partner_orders.created_by',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Имя',
          field: 'client_name',
          sortField: 'partner_orders.client_name',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Телефон',
          field: 'client_phone',
          sortField: 'partner_orders.client_phone',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Почта',
          field: 'client_email',
          sortField: 'partner_orders.client_email',
          showInTable: false,
          sort: 0,
        },
        {
          label: 'Комментарий',
          field: 'comment',
          sortField: 'partner_orders.comment',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Бюджет',
          field: 'budget_start.currency.text',
          sortField: 'partner_orders.budget_start',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Факт. бюджет',
          field: 'budget_finish.currency.text',
          sortField: 'partner_orders.budget_finish',
          showInTable: false,
          sort: 0,
        },
        {
          label: 'Дата добавления',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'dd.MM.yy',
          dateOutputFormat: 'dd.MM.yy',
          sortField: 'partner_orders.created_at',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Дата взятия в работу',
          field: 'status_active_at',
          type: 'date',
          dateInputFormat: 'dd.MM.yy',
          dateOutputFormat: 'dd.MM.yy',
          sortField: 'partner_orders.status_active_at',
          showInTable: false,
          sort: 0,
        },
        {
          label: 'Дата закрытия',
          field: 'partner_status_finish_at',
          type: 'date',
          dateInputFormat: 'dd.MM.yy',
          dateOutputFormat: 'dd.MM.yy',
          sortField: 'partner_orders.partner_status_finish_at',
          showInTable: false,
          sort: 0,
        },
        {
          label: 'Статус в кабинете',
          field: 'status.data.title',
          sortField: 'partner_orders.order_status_id',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Взято',
          field: 'winnerBy.data',
          sortField: 'partner_orders.winner_by',
          showInTable: false,
          sort: 0,
        },
        {
          label: 'Статус у партнера',
          field: 'partner_status.data.title',
          sortField: 'partner_orders.order_partner_status_id',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Адрес',
          field: 'address',
          sortField: 'partner_orders.address',
          showInTable: false,
          sort: 0,
        },
        {
          label: 'Широта',
          field: 'location_lat',
          sortField: 'partner_orders.location_lat',
          showInTable: false,
          sort: 0,
        },
        {
          label: 'Долгота',
          field: 'location_long',
          sortField: 'partner_orders.location_long',
          showInTable: false,
          sort: 0,
        },
      ],
    }
  },

  computed: {
    showButtonTakeAllOrders() {
      let data
      if (this.$store.state.user.role === 'admin') {
        data = this.fields.filter(item => item.status === 'Новый').map(i => i.id)
      } else {
        data = this.fields
          .filter(item => item.created_by !== this.$store.state.user.profile.id && item.status === 'Новый')
          .map(i => i.id)
      }

      if (data.length === this.fields.length) {
        return 'all'
      } else if (data.length > 0) {
        return 'success'
      } else {
        return 'none'
      }
    },
    selectedRows() {
      return this.$store.state.exchange.selectedItemsForDelete
    },
    fields() {
      let arr = []
      if (this.$store.state.exchange.selectedOrders !== undefined) {
        this.$store.state.exchange.selectedOrders.forEach(item => {
          arr.push({
            title: 'Заявка ' + item.number,
            created_by: item.created_by,
            winner_by: item.winner_by,
            id: item.id,
            status: item.status.data.title,
            full_data: item,
            data: [
              {
                id: 0,
                name: 'ФИО',
                value: item.client_name,
                new: false,
                edit: true,
                hideField: false,
                type: 'text',
              },
              {
                id: 1,
                name: 'Телефон',
                value: item.client_phone,
                new: false,
                edit: true,
                hideField: true,
                type: 'text',
              },
              {
                id: 2,
                name: 'Email',
                value: item.client_email,
                new: false,
                edit: true,
                hideField: true,
                type: 'text',
              },
              {
                id: 3,
                name: 'Комментарий',
                value: item.comment,
                new: false,
                edit: true,
                hideField: false,
                type: 'text',
              },
            ],
          })
        })
      }
      return arr
    },
    statusUpdate() {
      return this.$store.state.exchange.statusOrders
    },
    rightSidebarStore() {
      return this.$store.state.sidebars.rightSidebar
    },
    searchCount() {
      return this.$store.state.exchange.searchCount
    },
  },

  watch: {
    selectedRows() {
      ExchangeService.getSelectedOrders()
    },
    statusUpdate() {
      if (this.statusUpdate) this.updateData()
    },
    rightSidebarStore() {
      this.$store.commit('exchange/changeTakeInWorkPreloader', false)
    },
  },

  mounted() {
    const {id} = this.$route.params
    this.$store.commit('sidebars/changeLeftSidebar', true)
    this.$store.commit('sidebars/changeRightSidebar', id !== undefined)
    this.$store.commit('exchange/getAllExchange', [])

    if (id) {
      this.newOrder = false
      this.editOrder = false
      this.$store.commit('exchange/itemsForDelete', [id])
      this.$refs.exchangeDetails.getPartnerOrder()
    } else {
      this.$store.commit('exchange/itemsForDelete', [])
    }
  },

  methods: {
    updateData() {
      this.$refs.filter.getFilter()
    },
    getOrder() {
      const {id} = this.$route.params
      if (id) {
        this.currentOrder = this.$store.state.exchange.elements.find(el => el.id === id)
        this.$store.commit('sidebars/changeRightSidebar', true)
      }
    },
    onGetApplication(data) {
      ExchangeService.checkStatusOrder(data.id)
        .then(r => {
          if (r.data.data.status.data.title === 'Новый') {
            ExchangeService.changeStatusActiveOneOrder(data.id)
              .then(() => {
                this.$toast.success('Заявка в работе', {
                  timeout: 3000,
                })
                this.updateData()
              })
              .catch(e => {
                this.$store.commit('exchange/changeTakeInWorkPreloader', false)
                errorHandler(e)
              })
          } else {
            this.$store.commit('exchange/changeTakeInWorkPreloader', false)
            this.$toast.error(messages.errorValidForm)
          }
        })
        .catch(e => {
          this.$store.commit('exchange/changeTakeInWorkPreloader', false)
          errorHandler(e)
        })
    },
    async selectItem(data) {
      this.$store.commit('sidebars/changeRightSidebar', true)
      this.newOrder = false
      this.editOrder = false
      const {id} = this.$route.params
      if (id !== data.row.id) {
        await this.$router.push({name: 'exchange-id', params: {id: data.row.id}})
        this.$refs.exchangeDetails.getPartnerOrder()
      }
    },
    archiveItems() {
      this.$store.commit('sidebars/changeRightSidebar', false)
      ExchangeService.deleteItems().then(r => {
        this.$toast.success(r.data['message'])
        this.updateData()
      })
    },
    returnFromArchive() {
      ExchangeService.archiveItems().then(r => {
        this.$toast.success(r.data['message'])
        this.updateData()
      })
    },
    deleteItem() {
      this.$refs['table-container'].loadingDelete = true
      ExchangeService.deleteItems(true)
        .then(r => {
          this.$toast.success(r.data.message)
          this.$refs['table-container'].loadingDelete = false
          this.$refs['table-container'].showDeleteModal = false
          this.$store.commit('sidebars/changeRightSidebar', false)
          this.$store.commit('exchange/itemsForDelete', [])
          eventBus.$emit('new-state', false)
          this.updateData()
        })
        .catch(e => {
          errorHandler(e)
        })
    },
    async showRightSidebar() {
      await this.$store.commit('exchange/itemsForDelete', [])
      this.newOrder = true
      this.currentOrder = {}
      this.$store.commit('sidebars/changeRightSidebar', true)
      await this.$router.push({name: 'exchange'})
    },
  },
}
</script>