<template>
  <div class="tw-w-full tw-h-[calc(100%-45px)] md:tw-h-full">
    <div class="sidebar-right-container position-relative tw-overflow-y-auto tw-h-full tw-w-full" v-if="element">
      <SidebarRightHeader
        v-if="selectedItems.length < 2"
        :id="element.real_id"
        :creating="newElement"
        :editing="editElement"
        :element="element"
        :element-data="elementData"
        :is-show-edit-button="$can('edit', 'directory-item-update')"
        :loading="createItemLoading"
        :hash-id="element.id"
        :title="element.title"
        @close="closeRightSidebar"
        @update="$emit('on-update', element)"
        @edit-element="$emit('on-edit')"
        @on-show-events-list="$emit('on-show-events-list')"
      >
        <template v-slot:bottom>
          <div class="zem-collapse-table rows tw-mt-2 tw-w-full tw-flex-col" v-if="element">
            <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
              <div class="zem-collapse-table__column title d-flex align-center">Бюджет, руб.</div>
              <div class="zem-collapse-table__column text d-flex align-center">
                {{ element.budget.currency['no_style'] }}
              </div>
            </div>
            <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
              <div class="zem-collapse-table__column title d-flex align-center">Этап</div>
              <div class="zem-collapse-table__column text d-flex align-center">
                {{ element.status.title }}
              </div>
            </div>
            <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
              <div class="zem-collapse-table__column title d-flex align-center">Ответственный</div>
              <div class="zem-collapse-table__column text d-flex align-center">
                {{ element['responsibleBy'].title }}
              </div>
            </div>
          </div>
        </template>
      </SidebarRightHeader>

      <zem-collapse is-opened-default title="Участники" v-if="persons.length > 0">
        <OrderPersonsBlock :elements="persons" />
      </zem-collapse>

      <zem-collapse v-if="object" is-opened-default title="Объект">
        <OrderObjectBlock :element="object" />
      </zem-collapse>

      <zem-collapse is-opened-default title="События">
        <OrderEventsBlock :elements="events" :is-editing="editElement" :is-creating="newElement" />
      </zem-collapse>

      <zem-collapse is-opened-default title="Сметы">
        <OrderEstimatesBlock />
      </zem-collapse>
    </div>

    <preloader-wrapper :show="$store.state.other.isLoadingItem" />
  </div>
</template>

<script>
import ZemCard from '@/components/ui/ZemCard'
import {logger} from '@/assets/scripts/scripts'
import {options} from '@/mixins/options'
import SidebarRightHeader from '@/components/SidebarRightHeader.vue'
import PreloaderWrapper from '@/components/PreloaderWrapper.vue'
import ZemCollapse from '@/components/ui/ZemCollapse.vue'
import {PlusIcon} from 'vue-feather-icons'
import OrderEventsBlock from '@/components/Orders/OrderEventsBlock.vue'
import OrderEstimatesBlock from '@/components/Orders/OrderEstimatesBlock.vue'
import OrdersService from '@/services/orders.service'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemInput from '@/components/ui/ZemInput.vue'
import ObjectsService from '@/services/objects.service'
import OrderPersonsBlock from '@/components/Orders/OrderPersonsBlock.vue'
import OrderObjectBlock from '@/components/Orders/OrderObjectBlock.vue'

export default {
  emits: ['on-update', 'on-edit', 'on-close', 'on-show-events-list'],
  mixins: [options],
  components: {
    OrderObjectBlock,
    ZemInput,
    ZemDropdownList,
    OrderEstimatesBlock,
    OrderEventsBlock,
    ZemCollapse,
    OrderPersonsBlock,
    PreloaderWrapper,
    SidebarRightHeader,
    ZemCard,
    PlusIcon,
  },
  data() {
    return {
      editElement: false,
      newElement: false,
      elementData: null,
      title: '',
      id: '',
      element: null,
      object: null,
      persons: [],
      performers: [
        {
          id: 0,
          title: 'Менеджер',
          data: 'Иванов Иван Иванович',
        },
        {
          id: 1,
          title: 'Прораб',
          data: 'Сидоров Иван Иванович',
        },
        {
          id: 2,
          title: 'Снабженец',
          data: 'Петров Петр Петрович',
        },
      ],
      events: [
        {
          id: 0,
          title: 'Дата создания',
          data: '13.09.2023',
        },
        {
          id: 1,
          title: 'Дата завершения',
          data: '25.09.2024',
        },
      ],
      orderStatuses: [],
      currentElementId: null,
      createItemLoading: false,
    }
  },
  computed: {
    selectedItems() {
      return this.$store.state.orders.selectedItemsForDelete
    },
  },
  mounted() {
    OrdersService.getOrderStatuses().then(r => {
      this.orderStatuses = r.data.data.map(status => ({
        title: `${status.pipeline.data.name} - ${status.name}`,
        value: status.id,
      }))
    })
    ObjectsService.getObjects(true)
  },
  methods: {
    logger,
    getOrder() {
      this.$store.commit('other/onChangeLoadingItem', true)
      const {id} = this.$route.params
      const defaultValue = {
        title: null,
        value: null,
      }
      OrdersService.getOrder(id)
        .then(r => {
          const {responsible_by, status, object, object_id, budget, pipeline} = r.data.data
          this.element = {
            ...r.data.data,
            responsibleBy: responsible_by
              ? {
                  title: responsible_by.data.name,
                  value: responsible_by.data.id,
                }
              : defaultValue,
            status: status
              ? {
                  title: `${pipeline.data.name} - ${status.data.name}`,
                  value: status.data.id,
                }
              : defaultValue,
            object: object
              ? {
                  title: object.data.name,
                  value: object.data.id,
                }
              : defaultValue,
            objectValue: object,
            budgetEdit: budget.currency.value,
          }

          if (object_id) {
            ObjectsService.getObject(object_id).then(r => {
              this.object = r.data.data
            })
          } else {
            this.object = null
          }

          const participants = r.data.data.participants.data
          let titlesParticipants = []
          let resultParticipants = []
          let counter = 0

          for (let participant of participants) {
            if (!titlesParticipants.includes(participant.community_status.data.title)) {
              titlesParticipants.push(participant.community_status.data.title)
              resultParticipants.push({
                title: participant.community_status.data.title,
                id: participant.human.data.id,
                value: [
                  {
                    human_position: participant.human_position.data.title,
                    human: participant.human.data.fio,
                  },
                ],
              })
            } else {
              const i = resultParticipants.findIndex(el => participant.community_status.data.title === el.title)
              resultParticipants[i].value.push({
                human_position: participant.human_position.data.title,
                human: participant.human.data.fio,
              })
            }
            counter++
          }

          this.persons = resultParticipants
          this.$store.commit('other/onChangeLoadingItem', false)
        })
        .catch(e => {
          if (e.response.status === 422 && this.$route.name !== 'orders') this.$router.push({name: 'orders'})
          this.closeRightSidebar()
        })
    },
    closeRightSidebar() {
      this.editElement = false
      this.newElement = false
      this.$router.push({name: 'orders'})
      this.$emit('on-close')
      this.$store.commit('sidebars/changeRightSidebar', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebar-right-container {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  -moz-flex-direction: column;
  overflow-y: auto;

  .zem-dropdown {
    margin-top: 0;
  }
}

.colored {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-accent;

  img {
    margin-right: 3px;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1024px) {
  .sidebar-right-container {
    width: 100%;
    height: calc(100% - 45px);
    overflow: auto;
  }
}
</style>