<template>
  <div class="zem-collapse-table rows">
    <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
      <div class="zem-collapse-table__column title">Название</div>
      <RouterLink
        class="zem-collapse-table__column tw-text-xs tw-text-[#0DB2B2] hover:tw-underline tw-cursor-pointer tw-leading-[1] tw-font-semibold"
        :to="`/objects/${element.id}`"
        target="_blank"
      >
        <span v-tippy="{content: 'Перейти в объект'}">{{ element.name }}</span>
      </RouterLink>
    </div>
    <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
      <div class="zem-collapse-table__column title">Артикул</div>
      <div class="zem-collapse-table__column text">
        {{ element.sku }}
      </div>
    </div>
    <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
      <div class="zem-collapse-table__column title">Регион</div>
      <div class="zem-collapse-table__column text">
        {{ element.region ? element.region.data.name : '' }}
      </div>
    </div>
    <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
      <div class="zem-collapse-table__column title">Шоссе</div>
      <div class="zem-collapse-table__column text">
        {{ element.highway ? element.highway.data.name : '' }}
      </div>
    </div>
    <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
      <div class="zem-collapse-table__column title">Адрес</div>
      <div class="zem-collapse-table__column text">
        {{ element.address }}
      </div>
    </div>
    <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
      <div class="zem-collapse-table__column title">Координаты</div>
      <div class="zem-collapse-table__column text">
        {{ element.coordinates }}
      </div>
    </div>
    <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
      <div class="zem-collapse-table__column title">Удаленность, км.</div>
      <div class="zem-collapse-table__column text">
        {{ element.distance_from_mkad }}
      </div>
    </div>
    <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
      <div class="zem-collapse-table__column title">Площадь объекта, кв.м.</div>
      <div class="zem-collapse-table__column text">
        {{ element.square }}
      </div>
    </div>
    <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
      <div class="zem-collapse-table__column title">Кадастровый номер</div>
      <div class="zem-collapse-table__column text">
        {{ element.cadastral_number }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    element: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-dropdown {
  margin-top: 0;
}
</style>
